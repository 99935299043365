import React from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { FaUser, FaCode, FaEnvelope, FaGlobeEurope, FaGithub, FaLinkedinIn } from "react-icons/fa";

const Navbar = ({ activeSection, setActiveSection }) => {
  const { t, i18n } = useTranslation();

  const sections = [
    { id: "about", title: t("menu.0"), Icon: FaUser },
    { id: "projects", title: t("menu.1"), Icon: FaCode },
    { id: "contact", title: t("menu.2"), Icon: FaEnvelope },
  ];

  const changeLanguage = () => {
    const newLang = i18n.language === "de" ? "en" : "de";
    i18n.changeLanguage(newLang);
  };

  const handleNavClick = (section) => {
    setActiveSection(section);
  };

  const SocialLinks = () => (
    <div className="flex items-center space-x-4">
      <a
        href="https://github.com/zioan"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 transition-colors duration-200 hover:text-white"
        aria-label="GitHub"
      >
        <FaGithub size={20} />
      </a>
      <a
        href="https://www.linkedin.com/in/ioan-zaharia"
        target="_blank"
        rel="noopener noreferrer"
        className="text-gray-300 transition-colors duration-200 hover:text-white"
        aria-label="LinkedIn"
      >
        <FaLinkedinIn size={20} />
      </a>
    </div>
  );

  return (
    <motion.header
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      className="sticky top-0 left-0 z-50 w-full bg-gray-900 border-b shadow-lg bg-opacity-95 border-blue-500/30"
    >
      <nav className="container px-6 py-4 mx-auto">
        {/* Desktop Navigation */}
        <div className="items-center justify-between hidden md:flex">
          {/* Logo/Avatar */}
          <button className="flex items-center space-x-3 group" onClick={() => handleNavClick("about")}>
            <motion.img whileHover={{ scale: 1.1 }} src="/assets/me.png" alt="developer avatar" className="w-12 h-12 bg-gray-700 rounded-full" />
            <h3 className="text-xl font-bold text-white">IOAN ZAHARIA</h3>
          </button>

          <div className="flex items-center space-x-8">
            {sections.map((section) => (
              <button
                key={section.id}
                onClick={() => handleNavClick(section.id)}
                className={`relative px-3 py-2 text-sm transition-colors duration-200 ${
                  activeSection === section.id ? "text-blue-400" : "text-gray-300 hover:text-white"
                }`}
              >
                {section.title}
                {activeSection === section.id && (
                  <motion.div
                    layoutId="activeSection"
                    className="absolute bottom-0 left-0 right-0 h-0.5 bg-blue-500"
                    initial={false}
                    transition={{ type: "spring", stiffness: 380, damping: 30 }}
                  />
                )}
              </button>
            ))}
            <SocialLinks />
            <button
              onClick={changeLanguage}
              className="px-3 py-1 text-sm text-gray-300 transition-colors duration-200 hover:text-white"
              aria-label="Change language"
            >
              {i18n.language === "de" ? "Deutsch" : "English"}
            </button>
          </div>
        </div>

        {/* Mobile Navigation */}
        <div className="md:hidden">
          <div className="flex items-center justify-between mb-2">
            <button className="flex items-center space-x-2" onClick={() => handleNavClick("about")}>
              <motion.img whileHover={{ scale: 1.1 }} src="/assets/me.png" alt="developer avatar" className="w-10 h-10 bg-gray-700 rounded-full" />
              <h3 className="text-sm font-bold text-white">IOAN ZAHARIA</h3>
            </button>
            {/* Social and Language Links */}
            <div className="flex items-center space-x-4">
              <SocialLinks />
              <button
                onClick={changeLanguage}
                className="flex items-center justify-center w-8 h-8 text-gray-300 transition-colors duration-200 rounded-full hover:text-white hover:bg-gray-700"
                aria-label="Change language"
              >
                <FaGlobeEurope size={20} />
              </button>
            </div>
          </div>

          <div className="flex items-center justify-around pt-2 border-t border-gray-700">
            {sections.map((section) => (
              <button key={section.id} onClick={() => handleNavClick(section.id)} className="flex flex-col items-center px-4 py-1 space-y-1">
                <div
                  className={`p-2 rounded-lg transition-colors duration-200 ${
                    activeSection === section.id ? "bg-blue-500 text-white" : "text-gray-400 hover:text-white"
                  }`}
                >
                  <section.Icon size={20} />
                </div>
                <span className={`text-xs ${activeSection === section.id ? "text-blue-400" : "text-gray-400"}`}>{section.title}</span>
              </button>
            ))}
          </div>
        </div>
      </nav>
    </motion.header>
  );
};

export default Navbar;
